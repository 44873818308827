import ControllerBalance from './player/ControllerBalance';
import ControllerBet from './player/ControllerBet';
import ControllerWin from './player/ControllerWin';
import ControllerSpin from './player/ControllerSpin';
import ControllerTotalWin from './player/ControllerTotalWin';
import ControllerBetSelect from './player/ControllerBetSelect';
import ControllerAutoPlay from './player/ControllerAutoPlay';
import ControllerInfo from './player/ControllerInfo';
import ControllerMenu from './player/ControllerMenu';
import ControllerFullScreen from './player/ControllerFullScreen';
import ControllerSound from './player/ControllerSound';
import ControllerMaxBet from './player/ControllerMaxBet';
import ControllerBetSelectorTable from './player/ControllerBetSelectorTable';
import ControllerTournamentsLabel from './player/ControllerTournamentsLabel';

import ControllerHonesty from './system/ControllerHonesty';
import ControllerTournaments from './system/ControllerTournaments';
import ControllerNotifications from './notifications/ControllerNotificationsManager';
import ControllerTournamentsStartScreen from './system/ControllerTournamentsStartScreen';
import ControllerModalTournamentEnd from './system/ControllerModalTournamentEnd';
import ControllerModalTournamentDetails from './system/ControllerModalTournamentDetails';
import ControllerSidebar from './system/ControllerSidebar';

export {
  ControllerBalance,
  ControllerBet,
  ControllerWin,
  ControllerSpin,
  ControllerTotalWin,
  ControllerBetSelect,
  ControllerAutoPlay,
  ControllerInfo,
  ControllerMenu,
  ControllerFullScreen,
  ControllerSound,
  ControllerMaxBet,
  ControllerBetSelectorTable,
  ControllerHonesty,
  ControllerTournaments,
  ControllerTournamentsLabel,
  ControllerNotifications,
  ControllerTournamentsStartScreen,
  ControllerModalTournamentEnd,
  ControllerSidebar,
  ControllerModalTournamentDetails,
};
