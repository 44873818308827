import BaseController from './basePlayerController';
import eControllerTypes from './eControllerTypes';

export default class ControllerAutoPlay extends BaseController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_AUTO_SPIN, ...props });
    this._eButtonsTypes = {
      EBT_AUTO_SPIN: 'auto_spin_button',
    };
    this._eEventTypes = {
      AUTO_SPIN_CLICK: this.getEventName(this._eButtonsTypes.EBT_AUTO_SPIN),
    };
    this._eElementsTypes = {
      EET_AUTO_SPIN_BUTTON: this._eButtonsTypes.EBT_AUTO_SPIN,
    };

    this.init(container);
  }

  toggleActive(value) {
    if (value) {
      this.interactiveElements[this._eElementsTypes.EET_AUTO_SPIN_BUTTON].classList.add('toggled_on');
    } else {
      this.interactiveElements[this._eElementsTypes.EET_AUTO_SPIN_BUTTON].classList.remove('toggled_on');
    }
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_AUTO_SPIN_BUTTON]}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M66 36C66 52.56 52.56 66 36 66C19.44 66 9.33 49.32 9.33 49.32M9.33 49.32H22.89M9.33 49.32V64.32M6 36C6 19.44 19.32 6 36 6C56.01 6 66 22.68 66 22.68M66 22.68V7.68M66 22.68H52.68"
                stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M31.018 46H26.4684L33.4006 25.9192H38.8718L45.7942 46H41.2447L36.2147 30.508H36.0578L31.018 46ZM30.7336 38.1069H41.48V41.421H30.7336V38.1069Z"
                fill="white" />
          </svg>
        </button>`
  }
}
