import { blueWithDarkBlueStroke, whiteOceanGradientWithBlueShadow } from './fontStyles';
import GameModel from '../models/GameModel';
import { requestConfig, requestNewGame } from '../api/ControllerApi';
import { round } from '../utils/math';
import EMAdapter from '../platforms/everyMatrix/adapter';
import { formatCurrency } from '../utils/currency';

export default class ControllerUI {
  constructor(main, container) {
    this.LocalizationManager = window.OPWrapperService.localizations;
    this.main = main;
    this.container = container;
    const title = main.getChildByName('title');
    title.style = {
      ...title.style,
      ...whiteOceanGradientWithBlueShadow,
      textBaseline: 'middle'
    };

    this.buttonBet = main.getChildByName('button_bet');
    this.buttonBetText = this.buttonBet.getChildByName('game_start');
    this.coinView = main.getChildByName('coin_view');
    this.buttonClose = main.getChildByName('button_close');
    this.buttonClose.visible = false;
    this.buttonMenu = main.getChildByName('button_menu');

    this.popupRules = main.getChildByName('popup_info');
    this.popupInsufficientFunds = main.getChildByName('insufficient_funds');

    this.buttonBet.on('pointertap', this.buttonBetClicked.bind(this));
    this.buttonClose.on('pointertap', this.buttonCollectClicked.bind(this));
    this.buttonMenu.on('pointertap', () => {
      OPWrapperService.toggleSidebar();
    });

    OPWrapperService.sideBar.addButton({
      type: 'rules',
      styles: ['info'],
      onClick: () => this.popupRules.show(),
    });

    const winView = main.getChildByName('winView');
    this.winViewLabel = winView.getChildByName('labelValue');
    this.winViewLabel.text = this.LocalizationManager.getLocalizedText('game_start_game');
    this.winViewLabel.fitSize(true, true);
    const winTitle = winView.getChildByName('game_win');
    winTitle.style = { ...winTitle.style, ...blueWithDarkBlueStroke };

    this.balanceLabel = main.getChildByName('balanceLabel');
    this._updateBalanceLabel();
  }

  show(id, amount, currency) {
    this.buttonBetText.correctPosition('center', 'center');
    this.container.style.display = 'flex';
    this.main.visible = true;
    this.id = id;
    this.currency = currency;

    this.coinView.init();
  }

  buttonBetClicked() {
    if (
      GameModel.balance - GameModel.currentBet < 0
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    ) {
      this.popupInsufficientFunds.show();
      return;
    }

    EMAdapter.roundStart();

    this._enableUI(false);
    this.winViewLabel.text = this.LocalizationManager.getLocalizedText('game_good_luck');
    this.winViewLabel.fitSize(true, true);

    this.coinView.startAnimation();
    this.coinView.onStopCompleteOnce = () => requestAnimationFrame(this.onStopAnimationComplete.bind(this));

    if (!window.OPWrapperService.freeBetsController.isActive) {
      GameModel.balance = GameModel.balance - GameModel.currentBet;
      this._updateBalanceLabel();
    }
    window.OPWrapperService.freeBetsController.decreaseFreeBets();

    OPWrapperService.ControllerStatistic.bet = GameModel.currentBet;
    OPWrapperService.realityCheck.blockedRealityCheck = true;

    requestNewGame()
      .then((response) => {
        this.response = response;
        this.coinView.stopAnimation(response.is_win);
      });
  }

  buttonCollectClicked() {
    this.container.style.display = 'none';
  }

  onStopAnimationComplete() {
    EMAdapter.roundEnd();
    this._enableUI(true);
    GameModel.balance = this.response.balance;
    this._updateBalanceLabel();
    const coef = this.response.is_win ? 2 : 0.5;
    const winAmount = GameModel.currentBet * coef
    this.winViewLabel.text = `${winAmount} ${GameModel.currency}`;
    this.winViewLabel.fitSize(true, true);
    OPWrapperService.ControllerStatistic.win = winAmount;
    window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }

  _enableUI(value) {
    this.buttonClose.enabled = value;
    this.buttonBet.enabled = value;
    GameModel.betSelectorBlocked = !value;
  }

  _updateBalanceLabel() {
    const text = this.LocalizationManager.getLocalizedText('game_your_balance', [formatCurrency(GameModel.balance, GameModel.decimal), GameModel.currency]);
    const fontSize = text.length > 28 ? 40 : 50;
    this.balanceLabel.style.fontSize = fontSize;
    this.balanceLabel.text = text;
    this.balanceLabel.fitSize(true);
  }
}
