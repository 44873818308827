import BaseTournamentController from './baseTournamentController';
import eControllerTypes from './eTournamentControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerCompanyLogo extends BaseTournamentController {
  constructor(container) {
    super({ type: eControllerTypes.ETCT_COMPANY_LOGO });
    this._eElementsTypes = {
      EET_COMPANY_LABEL: `company_label`,
    };
    this._localizations = {
      [this._eElementsTypes.EET_COMPANY_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.company_label`,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<div class="system-ui-container__tournaments_main__body_description_promo_block__company">
              <svg width="161" height="32" viewBox="0 0 161 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_213_285)">
                  <mask id="path-1-inside-1_213_285" fill="white">
                    <path
                        d="M46.6376 24.9386C44.8556 24.9386 43.2176 24.5246 41.7236 23.6966C40.2476 22.8686 39.0686 21.7166 38.1866 20.2405C37.3226 18.7465 36.8906 17.0725 36.8906 15.2185C36.8906 13.3645 37.3226 11.6995 38.1866 10.2235C39.0686 8.74754 40.2476 7.59554 41.7236 6.76753C43.2176 5.93953 44.8556 5.52553 46.6376 5.52553C48.4196 5.52553 50.0486 5.93953 51.5246 6.76753C53.0186 7.59554 54.1886 8.74754 55.0346 10.2235C55.8986 11.6995 56.3306 13.3645 56.3306 15.2185C56.3306 17.0725 55.8986 18.7465 55.0346 20.2405C54.1706 21.7166 53.0006 22.8686 51.5246 23.6966C50.0486 24.5246 48.4196 24.9386 46.6376 24.9386ZM46.6376 20.7266C48.1496 20.7266 49.3556 20.2225 50.2556 19.2145C51.1736 18.2065 51.6326 16.8745 51.6326 15.2185C51.6326 13.5445 51.1736 12.2125 50.2556 11.2225C49.3556 10.2145 48.1496 9.71054 46.6376 9.71054C45.1076 9.71054 43.8836 10.2055 42.9656 11.1955C42.0656 12.1855 41.6156 13.5265 41.6156 15.2185C41.6156 16.8925 42.0656 18.2335 42.9656 19.2415C43.8836 20.2315 45.1076 20.7266 46.6376 20.7266Z" />
                    <path
                        d="M67.8092 9.52154C69.5732 9.52154 70.9772 10.0975 72.0212 11.2495C73.0832 12.3835 73.6142 13.9495 73.6142 15.9475V24.7496H69.0242V16.5685C69.0242 15.5605 68.7632 14.7775 68.2412 14.2195C67.7192 13.6615 67.0172 13.3825 66.1352 13.3825C65.2532 13.3825 64.5512 13.6615 64.0292 14.2195C63.5072 14.7775 63.2462 15.5605 63.2462 16.5685V24.7496H58.6292V9.68354H63.2462V11.6815C63.7142 11.0155 64.3442 10.4935 65.1362 10.1155C65.9282 9.71954 66.8192 9.52154 67.8092 9.52154Z" />
                    <path d="M81.1696 4.76953V24.7496H76.5526V4.76953H81.1696Z" />
                    <path
                        d="M99.6354 9.68354L90.1854 31.9046H85.2174L88.6734 24.2366L82.5444 9.68354H87.7014L91.1844 19.1065L94.6404 9.68354H99.6354Z" />
                    <path
                        d="M105.652 11.8165C106.102 11.1145 106.723 10.5475 107.515 10.1155C108.307 9.68354 109.234 9.46754 110.296 9.46754C111.538 9.46754 112.663 9.78254 113.671 10.4125C114.679 11.0425 115.471 11.9425 116.047 13.1125C116.641 14.2825 116.938 15.6415 116.938 17.1895C116.938 18.7375 116.641 20.1055 116.047 21.2935C115.471 22.4636 114.679 23.3726 113.671 24.0206C112.663 24.6506 111.538 24.9656 110.296 24.9656C109.252 24.9656 108.325 24.7496 107.515 24.3176C106.723 23.8856 106.102 23.3276 105.652 22.6436V31.9316H101.035V9.68354H105.652V11.8165ZM112.24 17.1895C112.24 16.0375 111.916 15.1375 111.268 14.4895C110.638 13.8235 109.855 13.4905 108.919 13.4905C108.001 13.4905 107.218 13.8235 106.57 14.4895C105.94 15.1555 105.625 16.0645 105.625 17.2165C105.625 18.3685 105.94 19.2775 106.57 19.9435C107.218 20.6095 108.001 20.9426 108.919 20.9426C109.837 20.9426 110.62 20.6095 111.268 19.9435C111.916 19.2595 112.24 18.3415 112.24 17.1895Z" />
                    <path d="M123.707 4.76953V24.7496H119.09V4.76953H123.707Z" />
                    <path
                        d="M125.865 17.1895C125.865 15.6415 126.153 14.2825 126.729 13.1125C127.323 11.9425 128.124 11.0425 129.132 10.4125C130.14 9.78254 131.265 9.46754 132.507 9.46754C133.569 9.46754 134.496 9.68354 135.288 10.1155C136.098 10.5475 136.719 11.1145 137.151 11.8165V9.68354H141.768V24.7496H137.151V22.6166C136.701 23.3186 136.071 23.8856 135.261 24.3176C134.469 24.7496 133.542 24.9656 132.48 24.9656C131.256 24.9656 130.14 24.6506 129.132 24.0206C128.124 23.3726 127.323 22.4636 126.729 21.2935C126.153 20.1055 125.865 18.7375 125.865 17.1895ZM137.151 17.2165C137.151 16.0645 136.827 15.1555 136.179 14.4895C135.549 13.8235 134.775 13.4905 133.857 13.4905C132.939 13.4905 132.156 13.8235 131.508 14.4895C130.878 15.1375 130.563 16.0375 130.563 17.1895C130.563 18.3415 130.878 19.2595 131.508 19.9435C132.156 20.6095 132.939 20.9426 133.857 20.9426C134.775 20.9426 135.549 20.6095 136.179 19.9435C136.827 19.2775 137.151 18.3685 137.151 17.2165Z" />
                    <path
                        d="M160.228 9.68354L150.778 31.9046H145.81L149.266 24.2366L143.137 9.68354H148.294L151.777 19.1065L155.233 9.68354H160.228Z" />
                  </mask>
                  <path
                      d="M46.6376 24.9386C44.8556 24.9386 43.2176 24.5246 41.7236 23.6966C40.2476 22.8686 39.0686 21.7166 38.1866 20.2405C37.3226 18.7465 36.8906 17.0725 36.8906 15.2185C36.8906 13.3645 37.3226 11.6995 38.1866 10.2235C39.0686 8.74754 40.2476 7.59554 41.7236 6.76753C43.2176 5.93953 44.8556 5.52553 46.6376 5.52553C48.4196 5.52553 50.0486 5.93953 51.5246 6.76753C53.0186 7.59554 54.1886 8.74754 55.0346 10.2235C55.8986 11.6995 56.3306 13.3645 56.3306 15.2185C56.3306 17.0725 55.8986 18.7465 55.0346 20.2405C54.1706 21.7166 53.0006 22.8686 51.5246 23.6966C50.0486 24.5246 48.4196 24.9386 46.6376 24.9386ZM46.6376 20.7266C48.1496 20.7266 49.3556 20.2225 50.2556 19.2145C51.1736 18.2065 51.6326 16.8745 51.6326 15.2185C51.6326 13.5445 51.1736 12.2125 50.2556 11.2225C49.3556 10.2145 48.1496 9.71054 46.6376 9.71054C45.1076 9.71054 43.8836 10.2055 42.9656 11.1955C42.0656 12.1855 41.6156 13.5265 41.6156 15.2185C41.6156 16.8925 42.0656 18.2335 42.9656 19.2415C43.8836 20.2315 45.1076 20.7266 46.6376 20.7266Z"
                      stroke="url(#paint0_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path
                      d="M67.8092 9.52154C69.5732 9.52154 70.9772 10.0975 72.0212 11.2495C73.0832 12.3835 73.6142 13.9495 73.6142 15.9475V24.7496H69.0242V16.5685C69.0242 15.5605 68.7632 14.7775 68.2412 14.2195C67.7192 13.6615 67.0172 13.3825 66.1352 13.3825C65.2532 13.3825 64.5512 13.6615 64.0292 14.2195C63.5072 14.7775 63.2462 15.5605 63.2462 16.5685V24.7496H58.6292V9.68354H63.2462V11.6815C63.7142 11.0155 64.3442 10.4935 65.1362 10.1155C65.9282 9.71954 66.8192 9.52154 67.8092 9.52154Z"
                      stroke="url(#paint1_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path d="M81.1696 4.76953V24.7496H76.5526V4.76953H81.1696Z" stroke="url(#paint2_linear_213_285)"
                        stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path
                      d="M99.6354 9.68354L90.1854 31.9046H85.2174L88.6734 24.2366L82.5444 9.68354H87.7014L91.1844 19.1065L94.6404 9.68354H99.6354Z"
                      stroke="url(#paint3_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path
                      d="M105.652 11.8165C106.102 11.1145 106.723 10.5475 107.515 10.1155C108.307 9.68354 109.234 9.46754 110.296 9.46754C111.538 9.46754 112.663 9.78254 113.671 10.4125C114.679 11.0425 115.471 11.9425 116.047 13.1125C116.641 14.2825 116.938 15.6415 116.938 17.1895C116.938 18.7375 116.641 20.1055 116.047 21.2935C115.471 22.4636 114.679 23.3726 113.671 24.0206C112.663 24.6506 111.538 24.9656 110.296 24.9656C109.252 24.9656 108.325 24.7496 107.515 24.3176C106.723 23.8856 106.102 23.3276 105.652 22.6436V31.9316H101.035V9.68354H105.652V11.8165ZM112.24 17.1895C112.24 16.0375 111.916 15.1375 111.268 14.4895C110.638 13.8235 109.855 13.4905 108.919 13.4905C108.001 13.4905 107.218 13.8235 106.57 14.4895C105.94 15.1555 105.625 16.0645 105.625 17.2165C105.625 18.3685 105.94 19.2775 106.57 19.9435C107.218 20.6095 108.001 20.9426 108.919 20.9426C109.837 20.9426 110.62 20.6095 111.268 19.9435C111.916 19.2595 112.24 18.3415 112.24 17.1895Z"
                      stroke="url(#paint4_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path d="M123.707 4.76953V24.7496H119.09V4.76953H123.707Z" stroke="url(#paint5_linear_213_285)"
                        stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path
                      d="M125.865 17.1895C125.865 15.6415 126.153 14.2825 126.729 13.1125C127.323 11.9425 128.124 11.0425 129.132 10.4125C130.14 9.78254 131.265 9.46754 132.507 9.46754C133.569 9.46754 134.496 9.68354 135.288 10.1155C136.098 10.5475 136.719 11.1145 137.151 11.8165V9.68354H141.768V24.7496H137.151V22.6166C136.701 23.3186 136.071 23.8856 135.261 24.3176C134.469 24.7496 133.542 24.9656 132.48 24.9656C131.256 24.9656 130.14 24.6506 129.132 24.0206C128.124 23.3726 127.323 22.4636 126.729 21.2935C126.153 20.1055 125.865 18.7375 125.865 17.1895ZM137.151 17.2165C137.151 16.0645 136.827 15.1555 136.179 14.4895C135.549 13.8235 134.775 13.4905 133.857 13.4905C132.939 13.4905 132.156 13.8235 131.508 14.4895C130.878 15.1375 130.563 16.0375 130.563 17.1895C130.563 18.3415 130.878 19.2595 131.508 19.9435C132.156 20.6095 132.939 20.9426 133.857 20.9426C134.775 20.9426 135.549 20.6095 136.179 19.9435C136.827 19.2775 137.151 18.3685 137.151 17.2165Z"
                      stroke="url(#paint6_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <path
                      d="M160.228 9.68354L150.778 31.9046H145.81L149.266 24.2366L143.137 9.68354H148.294L151.777 19.1065L155.233 9.68354H160.228Z"
                      stroke="url(#paint7_linear_213_285)" stroke-width="22.5" mask="url(#path-1-inside-1_213_285)" />
                  <g clip-path="url(#clip1_213_285)">
                    <path
                        d="M27.1875 15C27.1875 21.731 21.731 27.1875 15 27.1875C8.26903 27.1875 2.8125 21.731 2.8125 15C2.8125 8.26903 8.26903 2.8125 15 2.8125C21.731 2.8125 27.1875 8.26903 27.1875 15Z"
                        stroke="url(#paint8_linear_213_285)" stroke-width="3.75" />
                    <ellipse cx="15" cy="15" rx="4.6875" ry="4.68751" fill="url(#paint9_linear_213_285)" />
                  </g>
                </g>
                <defs>
                  <linearGradient id="paint0_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint5_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint6_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint7_linear_213_285" x1="98.5594" y1="4.76953" x2="98.5594" y2="31.9316"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint8_linear_213_285" x1="15" y1="0.9375" x2="15" y2="29.0625"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <linearGradient id="paint9_linear_213_285" x1="15" y1="10.3125" x2="15" y2="19.6875"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F79F2D" />
                    <stop offset="1" stop-color="#FF4D4D" />
                  </linearGradient>
                  <clipPath id="clip0_213_285">
                    <rect width="161" height="32" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_213_285">
                    <rect width="30" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_COMPANY_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_COMPANY_LABEL])}</span>
            </div>`
  }
}
