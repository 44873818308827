import eControllerTypes from '../eControllerTypes';

export default {
  ETCT_HEADER: `${eControllerTypes.ECT_TOURNAMENTS}__header`,
  ETCT_BANNER: `${eControllerTypes.ECT_TOURNAMENTS}__banner`,
  ETCT_BONUS_DETAILS: `${eControllerTypes.ECT_TOURNAMENTS}__bonus_details`,
  ETCT_MODAL_BONUS_DETAILS: `${eControllerTypes.ECT_TOURNAMENTS}__modal_bonus_details`,
  ETCT_COMPANY_LOGO: `${eControllerTypes.ECT_TOURNAMENTS}__company_logo`,
  ETCT_TABS: `${eControllerTypes.ECT_TOURNAMENTS}__tabs`,
  ETCT_ARCHIVE_LIST: `${eControllerTypes.ECT_TOURNAMENTS}__archive_list`,
}
