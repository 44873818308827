import { blueWithDarkBlueStroke } from '../ui/fontStyles';
import BasePopup from './base';

export default class PopupInfo extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    const title = this.getChildByName('info_title');
    const description = this.getChildByName('description');
    const goodLuck = this.getChildByName('good_luck');

    title.style = {
      ...title.style,
      ...blueWithDarkBlueStroke
    };
    description.style = {
      ...description.style,
      ...blueWithDarkBlueStroke,
      fontSize: 30,
    };
    goodLuck.style = {
      ...goodLuck.style,
      ...blueWithDarkBlueStroke
    };

    description.text = window.OPWrapperService.localizations.getLocalizedText('info_desc');

    this.getChildAt(0).alpha = 0.8;
  }
}
