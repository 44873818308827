import GameModel from '../../models/GameModel';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    this.controllerUI = null;

    GameModel.onBetChanged.add(this.stakeUpdate.bind(this));
    this.gameStarted = false;
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {

  }

  setAudio(value) {

  }

  showHelp() {

  }

  togglePaytable() {
    if(this.controllerUI.popupInfo.visible) {
      this.controllerUI.popupInfo.hide();
    } else {
      this.controllerUI.popupInfo.show();
    }
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    this.stakeUpdate();
  }

  audioToggle() {

  }

  stakeUpdate() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, GameModel.currentBet);
  }

  roundStart() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
  }

  roundEnd() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
  }

  closed() {

  }

}
