import BaseController from './basePlayerController';
import eControllerTypes from './eControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBet extends BaseController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_BET, ...props });
    this._eButtonsTypes = {
      EBT_DECREASE_BUTTON: 'decrease_button',
      EBT_INCREASE_BUTTON: 'increase_button',
    };
    this._eEventTypes =  {
      DECREASE_BUTTON_CLICK: this.getEventName(this._eButtonsTypes.EBT_DECREASE_BUTTON),
      INCREASE_BUTTON_CLICK: this.getEventName(this._eButtonsTypes.EBT_INCREASE_BUTTON),
    };
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
      EET_DECREASE_BUTTON: this._eButtonsTypes.EBT_DECREASE_BUTTON,
      EET_INCREASE_BUTTON: this._eButtonsTypes.EBT_INCREASE_BUTTON,
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.bet_label`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this._availableBets = [];
    this._currentBetIndex = -1;
    this.init(container);
  }

  setBets(availableBets) {
    this._availableBets = availableBets;
  }

  setCurrentBetIndex(index) {
    if (index < 0 || index >= this._availableBets.length) return;
    this._currentBetIndex = index;
    this.set(this._eElementsTypes.EET_VALUE, this._moneyFormat(this._availableBets[index]));
  }

  get maxIndex() {
    return this._availableBets.length - 1;
  }

  _getMarkup() {
    return `<div class="ui-container__bet_container">
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_DECREASE_BUTTON]} data-type="svg">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M31.9998 5.11114C17.1495 5.11114 5.11089 17.1497 5.11089 32C5.11089 46.8503 17.1495 58.8889 31.9998 58.8889C46.8501 58.8889 58.8887 46.8504 58.8887 32C58.8887 17.1497 46.8501 5.11114 31.9998 5.11114Z"
                  stroke="white" stroke-width="4" stroke-linejoin="round" />
              <path d="M19.5547 32H44.4436" stroke="white" stroke-width="4" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
          </button>
          <div class="ui-container__title-desc-col">
            <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
              ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
            </label>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
              0.12345678
            </span>
          </div>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_INCREASE_BUTTON]} data-type="svg">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M31.9998 5.11114C17.1495 5.11114 5.11089 17.1497 5.11089 32C5.11089 46.8503 17.1495 58.8889 31.9998 58.8889C46.8501 58.8889 58.8887 46.8504 58.8887 32C58.8887 17.1497 46.8501 5.11114 31.9998 5.11114Z"
                  stroke="white" stroke-width="4" stroke-linejoin="round" />
              <path d="M19.5547 32H44.4436" stroke="white" stroke-width="4" stroke-linecap="round"
                    stroke-linejoin="round" />
              <path d="M32 44.4446V19.5557" stroke="white" stroke-width="4" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
          </button>
        </div>`
  }
}
