import FlashLib from "flashlib";

export default class LocalizeTextField extends FlashLib.TextField {
  constructor(data, displayData) {
    super(data, displayData);

    const prefferedFontFamily = this.style.fontFamily;
    let fallback = 'Inter Extra Bold';
    if (/regular|medium/i.test(prefferedFontFamily)) fallback = 'Inter Semi Bold';

    this.style.fontFamily = [ prefferedFontFamily, fallback ];
    this.localizationManager = window.OPWrapperService.localizations
    this.localizationManager.addTextField(this);
  }

  setTextParams(...params) {
    this.text = this.localizationManager.getLocalizedText(this.name, params);
  }

  destroy(options) {
    this.localizationManager.removeTextField(this);
    super.destroy(options);
  }
}
