import * as PIXI from 'pixi.js';
window.PIXI = PIXI;
require("pixi-spine");

import './Imports';
import '../css/index.css';
import GameInterface from './GameInterface';
import OPWrapperService from './libs/op-wrapper-service';
import en from '../assets/i18n/en.json';
import enSocial from '../assets/i18n/en-social.json';

new OPWrapperService({
  buttons: [],
  bundle: 'coin',
  localizationBundle: 'coin',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
});

window.coinGameOP = new GameInterface();


