import BaseController from './basePlayerController';
import eControllerTypes from './eControllerTypes';

export default class ControllerSound extends BaseController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_SOUND, ...props });
    this._eButtonsTypes = {
      EBT_SOUND_MUTE: 'sound_mute_button',
      EBT_SOUND_UNMUTE: 'sound_unmute_button',
    };
    this._eEventTypes = {
      SOUND_MUTE_CLICK: this.getEventName(this._eButtonsTypes.EBT_SOUND_MUTE),
      SOUND_UNMUTE_CLICK: this.getEventName(this._eButtonsTypes.EBT_SOUND_UNMUTE),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_SOUND_MUTE]: this.onMuteClick.bind(this),
      [this._eButtonsTypes.EBT_SOUND_UNMUTE]: this.onUnmuteClick.bind(this),
    };
    this._eElementsTypes = {
      EET_SOUND_MUTE_BUTTON: this._eButtonsTypes.EBT_SOUND_MUTE,
      EET_SOUND_UNMUTE_BUTTON: this._eButtonsTypes.EBT_SOUND_UNMUTE,
    };

    this.init(container);

    this._soundMuted = false;
    this._showMuteButton()
  }

  setMuted(value) {
    this._soundMuted = value;
    if (this._soundMuted) {
      this._showUnmuteButton();
    } else {
      this._showMuteButton();
    }
  }

  emit(event, data) {
    super.emit(event, this._soundMuted);
  }

  onMuteClick() {
    this._soundMuted = true;
    this._showUnmuteButton();
  }

  onUnmuteClick() {
    this._soundMuted = false;
    this._showMuteButton();
  }

  _showMuteButton() {
    this.interactiveElements[this._eElementsTypes.EET_SOUND_MUTE_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON].classList.add('hidden');
  }

  _showUnmuteButton() {
    this.interactiveElements[this._eElementsTypes.EET_SOUND_MUTE_BUTTON].classList.add('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON].classList.remove('hidden');
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SOUND_MUTE_BUTTON]}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5 29.9999V41.9999C16.5 47.9999 19.5 50.9999 25.5 50.9999H29.79C30.9 50.9999 32.01 51.3299 32.97 51.8999L41.73 57.3899C49.29 62.1299 55.5 58.6799 55.5 49.7699V22.2299C55.5 13.2899 49.29 9.86986 41.73 14.6099L32.97 20.0999C32.01 20.6699 30.9 20.9999 29.79 20.9999H25.5C19.5 20.9999 16.5 23.9999 16.5 29.9999Z"
                stroke="white" stroke-width="4.5" />
          </svg>
        </button>
        <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON]}>
          <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5">
              <path d="m35.0003 19.5302v-2.24c0-6.9534-4.83-9.61337-10.71-5.9267l-6.8133 4.27c-.7467.4433-1.61.7-2.4733.7h-3.3367c-4.66667 0-7.00001 2.3333-7.00001 7v9.3333c0 4.6667 2.33334 7 7.00001 7h4.6667"/>
              <path d="m24.29 44.6366c5.88 3.6866 10.71 1.0033 10.71-5.9267v-8.4933"/>
              <path d="m43.89 21.98c2.1 5.0166 1.47 10.8733-1.89 15.3533"/>
              <path d="m49.3505 18.2002c3.43 8.1433 2.4033 17.6633-3.08 24.9667"/>
              <path d="m51.3337 4.66675-46.66671 46.66665"/>
            </g>
          </svg>
        </button>
`
  }
}
