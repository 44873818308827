import eTournamentControllerTypes from './eTournamentControllerTypes';
import BaseTournamentController from './baseTournamentController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';
import ControllerArchiveCard from './ControllerArchiveCard';
import { formatOrientation } from '../../../../utils/orientation';

export default class ControllerTournamentsArchiveList extends BaseTournamentController {
  constructor(container) {
    super({ type: eTournamentControllerTypes.ETCT_ARCHIVE_LIST });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eButtonsTypes = {
      EBT_BACK: 'button_back',
    }
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_BACK]: () => this.hide(),
    };
    this._eEventTypes = {
      BACK_CLICK: this.getEventName(this._eButtonsTypes.EBT_BACK),
      DETAILS_CLICK: this.getEventName('details'),
    };
    this._eElementsTypes = {
      EET_ROOT: 'root',
      EET_TITLE: 'title',
      EET_CONTAINER_LIST: 'container_list',
      EET_BUTTON_BACK: this._eButtonsTypes.EBT_BACK,
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.archive`,
    };

    this._cards = {};

    this.init(container);
    this.hide();
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_ROOT];
  }

  _onDataUpdated() {
    super._onDataUpdated();

    const listContainer = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_LIST];
    const archivedIdsArray = this._data.map(item => item.uniqueId);
    const archivedIds = new Set(archivedIdsArray);
    const alreadyExisted = new Set();

    this._cards = Object.keys(this._cards).reduce((acc, key) => {
      if (archivedIds.has(key)) {
        acc[key] = this._cards[key];
        alreadyExisted.add(key);
      } else {
        this._cards[key].remove();
      }

      return acc;
    }, {});

    for (let item of this._data) {
      const id = item.uniqueId;

      if (!alreadyExisted.has(id)) {
        const card = new ControllerArchiveCard(item)
        card.on(card.events[card.controllerType].DETAILS_CLICK, () => {
          console.log('tournament id: ', id);
          this.emit(this._eEventTypes.DETAILS_CLICK, id);
          this.hide();
        });

        this._cards[id] = card;
        card.init(listContainer);
      }
    }

    [...archivedIdsArray].reverse().forEach(id => listContainer.appendChild(this._cards[id].root));
  }

  get dataSelector() {
    return (data) => data.archived;
  }

  set scaleData(props) {
    const { scaleData } = props;
    this.root.setAttribute('data-orientation', formatOrientation(scaleData.orientation));
    Object.values(this._cards).forEach(card => card.scaleData = props);
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_ROOT]} class="system-ui-container__tournaments system-ui-container__tournaments_archive">
      <div class="system-ui-container__tournaments_main">
        <div class="system-ui-container__tournaments_main__header">
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_BACK]} class="system-ui-container__back_button">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 51.3333H35C46.6667 51.3333 51.3333 46.6667 51.3333 35V21C51.3333 9.33334 46.6667 4.66667 35 4.66667H21C9.33334 4.66667 4.66667 9.33334 4.66667 21V35C4.66667 46.6667 9.33334 51.3333 21 51.3333Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.94 36.2367L22.7267 28L30.94 19.7633" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          
          <div>
            <tournaments-logo class="tournament_logo"/>
          </div>
        </div>

        <div class="system-ui-container__tournaments_main__title system-ui-container__tournaments_archive_title">
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>
            ${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}
          </span>
        </div>

        <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_LIST]} class="system-ui-container__tournaments_main__body system-ui-container__tournaments_archive_list">
        </div>

    </div>
  </div>`
  }
}
