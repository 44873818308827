import BaseButton from '../base/Button';

export default class ButtonBet extends BaseButton {
  constructor(data, displayData) {
    super(data, displayData);

    this.labelTitle = this.getChildByName('title');
    //todo: make stroke
  }
}
