import BaseController from './basePlayerController';
import eControllerTypes from './eControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBetSelectorTable extends BaseController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_BET_SELECTOR_TABLE, ...props });
    this._initialButtonTypes = {
      EBT_MIN: 'min_bet_button',
      EBT_MAX: 'max_bet_button',
      EBT_RECENT_BET_0: 'recent_bet_button_0',
      EBT_RECENT_BET_1: 'recent_bet_button_1',
      EBT_RECENT_BET_2: 'recent_bet_button_2',
    };
    this._eEventTypes = {
      CHANGE_BET_CLICK: this.getEventName('change_bet'),
    };
    this._initialElementsTypes = {
      EET_LABEL: 'label',
      EET_BETS_CONTAINER: 'bets_container',
      EET_MIN_BET_BUTTON: this._initialButtonTypes.EBT_MIN,
      EET_MAX_BET_BUTTON: this._initialButtonTypes.EBT_MAX,
      EET_RECENT_BET_BUTTON_0: this._initialButtonTypes.EBT_RECENT_BET_0,
      EET_RECENT_BET_BUTTON_1: this._initialButtonTypes.EBT_RECENT_BET_1,
      EET_RECENT_BET_BUTTON_2: this._initialButtonTypes.EBT_RECENT_BET_2,
    };
    this._eButtonsTypes = this._initialButtonTypes;
    this._eElementsTypes = this._initialElementsTypes;
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.choose_bet`,
    };

    this._availableBets = [];
    this._currentBetIndex = -1;
    this._buttonsFontRatio = 'big';
    this._isOverlayToggler = true;

    this.init(container);
    this.hide();
  }

  setBets(availableBets) {
    this._availableBets = availableBets;
    this._updateFontRatio();
    this._eButtonsTypes = { ...this._initialButtonTypes, ...Object.fromEntries(this._availableBets.map((_, index) => [`EBT_BET_${index}`, `bet_button_${index}`])) };
    this._eElementsTypes = { ...this._initialElementsTypes, ...Object.fromEntries(this._availableBets.map((_, index) => [`EET_BET_BUTTON_${index}`, this._eButtonsTypes[`EBT_BET_${index}`]])) };
    this._interactiveElementsIds = null;
    const event = this.events[this.controllerType].CHANGE_BET_CLICK;
    let markup = ``;

    for (let i = 0; i < this._availableBets.length; i++) {
      if (i % 5 === 0) {
        markup += `<div>`
      }
      //todo: moneyFormat.format?
      markup += `<button id=${this.interactiveElementsIds[this._eElementsTypes[`EET_BET_BUTTON_${i}`]]} data-event="${event}" data-bet-index=${i} data-color="bets_table_primary">
                   <span data-font-ratio="${this._buttonsFontRatio}">${this._availableBets[i]}</span>
                 </button>`;
      if (i % 5 === 4 || i === this._availableBets.length - 1) {
        markup += `</div>`
      }
    }

    this.set(this._eElementsTypes.EET_BETS_CONTAINER, markup);
    this.interactiveElements[this._eElementsTypes.EET_MAX_BET_BUTTON].setAttribute('data-bet-index', this._availableBets.length - 1);
    this._saveInteractiveElements();
    this.addActions();
  }

  setRecentBets(recentBets) {
    if (!this._availableBets) throw new Error('Available bets must be provided before setting recent bets indexes');
    [
      this._eElementsTypes.EET_RECENT_BET_BUTTON_0,
      this._eElementsTypes.EET_RECENT_BET_BUTTON_1,
      this._eElementsTypes.EET_RECENT_BET_BUTTON_2
    ]
      .forEach((type, index) => {
        this.interactiveElements[type].setAttribute('data-bet-index', recentBets[index]);
        this.set(type, `<span data-font-ratio="${this._buttonsFontRatio}">${this._availableBets[recentBets[index]]}</span>`);
      })
  }

  setCurrentBetIndex(index) {
    if (index < 0 || index >= this._availableBets.length) return;

    if (this._currentBetIndex >= 0) {
      const prevSelected = this.interactiveElements[this._eElementsTypes[`EET_BET_BUTTON_${this._currentBetIndex}`]];
      if (prevSelected) prevSelected.classList.remove('selected');
    }
    this._currentBetIndex = index;
    const selected = this.interactiveElements[this._eElementsTypes[`EET_BET_BUTTON_${this._currentBetIndex}`]];
    if (selected) selected.classList.add('selected');
  }

  addActions() {
    for (let key in this._eButtonsTypes) {
      const type = this._eButtonsTypes[key];
      const button = this._interactiveElements[type];
      button.onclick = () => {
        const event = button.getAttribute('data-event');
        const betIndex = parseInt(button.getAttribute('data-bet-index'));
        this.emit(event, betIndex);
      }
    }
  }

  _updateFontRatio() {
    let maxDecimal = 0;
    for (let bet of this._availableBets) {
      let splitted = String(bet).split('.');
      if (splitted.length < 2) continue;

      maxDecimal = splitted[1].length > maxDecimal ? splitted[1].length : maxDecimal;
    }

    this._buttonsFontRatio = maxDecimal > 2 ? 'small' : 'big';
  }

  get currentBetIndex() {
    return this._currentBetIndex;
  }

  get maxIndex() {
    return this._availableBets.length - 1;
  }

  _getMarkup() {
    const events = this.events[this.controllerType];
    return `<div>
             <div class="ui-container__bet_selector_container__title">
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
               ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
              </span>
            </div>
            <div class="ui-container__bet_selector_container__table">
            <div>
              <div>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_MIN_BET_BUTTON]} class="ui-container__bet_selector_container__table__min_button" data-event="${events.CHANGE_BET_CLICK}" data-bet-index="0" data-color="secondary">
                  <span>MIN</span>
                </button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_RECENT_BET_BUTTON_0]} data-event="${events.CHANGE_BET_CLICK}" data-color="secondary"></button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_RECENT_BET_BUTTON_1]} data-event="${events.CHANGE_BET_CLICK}" data-color="secondary"></button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_RECENT_BET_BUTTON_2]} data-event="${events.CHANGE_BET_CLICK}" data-color="secondary"></button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_BET_BUTTON]} class="ui-container__bet_selector_container__table__max_button" data-event="${events.CHANGE_BET_CLICK}" data-color="secondary">
                  <span>MAX</span>
                </button>
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_BETS_CONTAINER]}>
                <div>
                  <button></button>
                  <button></button>
                  <button></button>
                  <button></button>
                </div>
              </div>
             </div>
            </div>
           </div>`
  }
}
