import BaseController from './baseSystemController';
import eControllerTypes from '../system/eControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import ScaleManager from '../../../scaleManager/ScaleManager';

export default class ControllerHonesty extends BaseController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_HONESTY, ...props });
    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_DESCRIPTION: 'description',
      EET_RESULT_TITLE: 'result',
      EET_RESULT_INPUT: 'result_input',
      EET_SALT_TITLE: 'salt',
      EET_SALT_INPUT: 'salt_input',
      EET_PREV_HASH_TITLE: 'prev_hash',
      EET_PREV_HASH_INPUT: 'prev_hash_input',
      EET_CURRENT_HASH_TITLE: 'current_hash',
      EET_CURRENT_HASH_INPUT: 'current_hash_input',
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.label`,
      [this._eElementsTypes.EET_DESCRIPTION]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.description`,
      [this._eElementsTypes.EET_RESULT_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.result_label`,
      [this._eElementsTypes.EET_SALT_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.salt_label`,
      [this._eElementsTypes.EET_PREV_HASH_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.prev_hash_label`,
      [this._eElementsTypes.EET_CURRENT_HASH_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.honestyControl.current_hash_label`,
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: this.hide.bind(this),
    };

    this.init(container);
    this.hide();
  }

  set scaleData({ scaleData, rootScale }) {
    const safeZone = ScaleManager.safeZone ? ScaleManager.safeZone : {};
    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this._container,
      relativeScale: scaleData.scale || 1,
      defaultWidth: safeZone.width,
      defaultHeight: safeZone.height,
    });
  }

  _getMarkup() {
    return `<div class="ui-container__honesty_container__content">
             <div class="ui-container__honesty_container__title">
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
               ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
              </span>
              <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} data-color="transparent">
                <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                    <path d="m15.2812 24.7165 9.4334-9.4333"/>
                    <path d="m24.7146 24.7165-9.4334-9.4333"/>
                    <path d="m14.9987 36.6665h10c8.3333 0 11.6667-3.3334 11.6667-11.6667v-10c0-8.33334-3.3334-11.66667-11.6667-11.66667h-10c-8.33334 0-11.66667 3.33333-11.66667 11.66667v10c0 8.3333 3.33333 11.6667 11.66667 11.6667z"/>
                  </g>
                </svg>
              </button>
            </div>
            <div class="ui-container__honesty_container__body">
              <div class="ui-container__honesty_container__desc" id=${this.interactiveElementsIds[this._eElementsTypes.EET_DESCRIPTION]}>
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_DESCRIPTION])}
              </div>
              <div class="ui-container__honesty_container__info">
                <div class="ui-container__honesty_container__info__item">
                  <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_RESULT_TITLE]} class="ui-container__honesty_container__info__title">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_RESULT_TITLE])}
                  </div>
                  <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_RESULT_INPUT]} readonly>
                </div>
                <div class="ui-container__honesty_container__info__item">
                  <dev id=${this.interactiveElementsIds[this._eElementsTypes.EET_SALT_TITLE]} class="ui-container__honesty_container__info__title">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_SALT_TITLE])}
                  </dev>
                  <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_SALT_INPUT]} readonly>
                </div>
                <div class="ui-container__honesty_container__info__item">
                  <dev id=${this.interactiveElementsIds[this._eElementsTypes.EET_PREV_HASH_TITLE]} class="ui-container__honesty_container__info__title">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_PREV_HASH_TITLE])}
                  </dev>
                  <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_PREV_HASH_INPUT]} readonly>
                </div>
                <div class="ui-container__honesty_container__info__item">
                  <dev id=${this.interactiveElementsIds[this._eElementsTypes.EET_CURRENT_HASH_TITLE]} class="ui-container__honesty_container__info__title">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_CURRENT_HASH_TITLE])}
                  </dev>
                  <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_CURRENT_HASH_INPUT]} readonly>
                </div>
              </div>
            </div>
           </div>`
  }
}
