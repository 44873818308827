import BaseSystemController from '../system/baseSystemController';
import eControllerTypes from './eControllerTypes';
import eButtonsTypes from '../../../sideBar/eButtonsTypes';
import LocalizationManager from '../../../i18n/LocalizationManager';
import ButtonSound from '../../../sideBar/ButtonSound';
import { EMPostMessageTypes } from '../../../platforms/everyMatrix/constants';
import { getUrlParam } from '../../../utils/url';
import { isIframe } from '../../../utils/isIframe';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import ScaleManager from '../../../scaleManager/ScaleManager';

export default class ControllerSidebar extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_SIDEBAR, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };
    this._eButtonsTypes = {
      EBT_CLOSE: eButtonsTypes.EBT_CLOSE,
    }
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: () => {
        this.hide();
        window.OPWrapperService.model.data.initConfig.onButtonClick();
      },
    };
    this._eElementsTypes = {
      EET_OVERLAY: 'overlay',
      EET_SCALE_ROOT: 'scale_root',
      EET_SIDEBAR: 'sidebar',
      EET_TITLE: 'title',
      EET_GAME_VERSION_CONTAINER: 'game_version_container',
      EET_BUTTON_CLOSE: this._eButtonsTypes.EBT_CLOSE,
      EET_CONTAINER_BUTTONS: 'container_buttons',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.sideBar.title`,
    }

    this._eEventTypes = Object.values(eButtonsTypes).reduce((acc, type) => {
      acc[type] = this.getEventName(type);
      return acc;
    }, {});

    this._buttonsPriority = {
      [eButtonsTypes.EBT_HOME]: 0,
      [eButtonsTypes.EBT_PAYTABLE]: 1,
      [eButtonsTypes.EBT_RULES]: 2,
      [eButtonsTypes.EBT_SETTINGS]: 3,
      [eButtonsTypes.EBT_SOUND]: 4,
      [eButtonsTypes.EBT_INFO]: 5,
      [eButtonsTypes.EBT_LEADERBOARD]: 6,
      [eButtonsTypes.EBT_GAME_LIMITS]: 7,
      [eButtonsTypes.EBT_TOURNAMENTS]: 8,
    }

    this._initializedButtons = new Set();

    this._isSpinBlocker = true;

    this._container = container;
    this._onTransitionEnd = this._onTransitionEnd.bind(this);
    this._onOutsideClick = this._onOutsideClick.bind(this);
  }

  init() {
    super.init(this._container);

    const sidebar = this.interactiveElements[this._eElementsTypes.EET_SIDEBAR];
    sidebar.ontransitionend = this._onTransitionEnd;

    const overlay = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    overlay.onclick = this._onOutsideClick;

    this.scaleRoot = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
    this.scaleData = { scaleData: this._scaleData };

    this._tryInitHomeButton();
    this._tryInitLimitsButton();
    this._tryInitTournamentsButton();
    this._initButtons();
    super.hide();
  }

  show() {
    super.show();
    void this.interactiveElements[this._eElementsTypes.EET_SIDEBAR].offsetWidth;
    this.interactiveElements[this._eElementsTypes.EET_SIDEBAR].classList.add('open');
  }

  hide() {
    const sidebar = this.interactiveElements[this._eElementsTypes.EET_SIDEBAR];
    sidebar.classList.remove('open');
  }

  tryInitTournamentsButton() {
    if (this._tryInitTournamentsButton()) {
      this.updateButtons();
    }
  }

  _onOutsideClick(e) {
    if (e.target === this.interactiveElements[this._eElementsTypes.EET_OVERLAY]) this.hide();
  }

  _initButtons() {
    this._removeButtons();
    this._config.buttons.sort((a, b) => this._buttonsPriority[a.type] - this._buttonsPriority[b.type]);
    const buttonsContainer = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_BUTTONS];
    buttonsContainer.append(...this._buttonsList(this._config.buttons));
  }

  _removeButtons() {
    this._initializedButtons.clear();
    const buttonsContainer = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_BUTTONS];
    buttonsContainer.innerHTML = '';
    this.homeButton = null;
    this.rulesButton = null;
    this.ButtonSound = null;
  }

  updateButtons() {
    if (!this._mounted) return;
    this._initButtons();
  }

  addButton(data) {
    this._addButtons([data]);
    this.updateButtons()
  }

  isButtonInitialized(type) {
    return this._initializedButtons.has(type);
  }

  _onTransitionEnd() {
    const sidebar = this.interactiveElements[this._eElementsTypes.EET_SIDEBAR];
    if (sidebar.classList.contains('open')) return;
    super.hide();
  }

  _buttonsList(buttons) {
    return buttons.map((item) => {
      this._initializedButtons.add(item.type);

      if (item.type === eButtonsTypes.EBT_SOUND) {
        this.ButtonSound = new ButtonSound(item);
        return this.ButtonSound.element;
      }

      return this._createButton(item);
    });
  }

  _createButton({ type, styles, onClick }) {
    const button = document.createElement('button');

    button.addEventListener('click', () => {
      this.hide();
      onClick();
      this.emit(this.getEventName(type));
      window.OPWrapperService.model.data.initConfig.onButtonClick();
    });
    if (type === eButtonsTypes.EBT_HOME) this.homeButton = button;
    if (type === eButtonsTypes.EBT_RULES) this.rulesButton = button;

    button.innerHTML = `<div class="icon ${styles && styles.join(' ')}"></div>
                        <span class="text">${LocalizationManager.getLocalizedText(`OPWrapperService.buttons.${type}`)}</span>`
    return button;
  };

  _addButtons(buttons = []) {
    this._config.buttons = [...this._config.buttons, ...buttons];
  }

  _tryInitLimitsButton() {
    if (window.OPWrapperService.model.data.partnerConfig.available_bets && window.OPWrapperService.model.data.partnerConfig.maxWin) {
      this.addButton({
        type: eButtonsTypes.EBT_GAME_LIMITS,
        styles: ['gameLimits'],
        onClick: () => window.OPWrapperService.gameLimitsWindow.show(),
      });
    }
  }

  _tryInitHomeButton() {
    const data = this._getHomeButtonData();
    if (data) this.addButton(data);
  }

  _tryInitTournamentsButton() {
    if (window.OPWrapperService.model.data.tournamentsInfo &&
      (window.OPWrapperService.model.data.tournamentsInfo.active.length ||
        window.OPWrapperService.model.data.tournamentsInfo.archived.length) &&
      !this._initializedButtons.has(eButtonsTypes.EBT_TOURNAMENTS)) {
      this.addButton({
        type: eButtonsTypes.EBT_TOURNAMENTS,
        styles: ['tournament'],
        onClick: () => window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_TOURNAMENTS),
      });

      return true;
    }
  }

  _getHomeButtonData() {
    const search = window.location.search;
    const history = window.history;
    const homeURL = getUrlParam('lobbyUrl');
    let info = { type: eButtonsTypes.EBT_HOME, styles: ['home'] };
    const iFrameButton = window.OPWrapperService.model.data.partnerConfig.iFrameHomeButton;
    const homeButtonHistoryBackDisable = window.OPWrapperService.model.data.partnerConfig.homeButtonHistoryBackDisable;
    if (!isIframe() && (homeURL || (!homeButtonHistoryBackDisable && window.history.length > 1))) {
      info.onClick = () => {
        if (homeURL) {
          window.top.location.href = homeURL;
        } else {
          history.back();
        }
      }
    } else if (homeURL && iFrameButton) {
      info.onClick = () => {
        window.top.location.href = homeURL;
        window.OPWrapperService.eventManager.dispatch(EMPostMessageTypes.PMT_CLOSED);
      }
    } else {
      if (!homeURL) console.warn(`No lobbyUrl presented: ${search}`);
      info = null;
    }

    return info;
  }

  set gameVersion(value) {
    this._gameVersion = value;
    this.interactiveElements[this._eElementsTypes.EET_GAME_VERSION_CONTAINER].textContent = `${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.sideBar.gameVersion`)} ${this._gameVersion}`;
  }

  get gameVersion() {
    return this._gameVersion;
  }

  set config(config) {
    this._config = config;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    if (!this._mounted) return;

    const orientation = this._scaleData.innerWidth <= this._scaleData.innerHeight ? 'V' : 'H';
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);

    const width = parseInt(window.getComputedStyle(this.scaleRoot).width);
    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.scaleRoot,
      relativeScale: uiScale,
      defaultWidth: width,
      defaultHeight: this._scaleData.innerHeight / uiScale + 2,
      forceLeftMargin: -1
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OVERLAY]} class="system-ui-container__sidebar_overlay">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__sidebar_wrapper">
                <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SIDEBAR]} class="system-ui-container__sidebar">
                  <div class="system-ui-container__sidebar__header">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</span>
                    <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_CLOSE]} class="system-ui-container__close_button">
                      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.9989 51.3331H34.9989C46.6655 51.3331 51.3322 46.6664 51.3322 34.9997V20.9997C51.3322 9.33305 46.6655 4.66638 34.9989 4.66638H20.9989C9.33219 4.66638 4.66553 9.33305 4.66553 20.9997V34.9997C4.66553 46.6664 9.33219 51.3331 20.9989 51.3331Z"
                            fill="#17142A" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M21.3965 34.6032L34.6032 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                        <path d="M34.6032 34.6032L21.3965 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                      </svg>
                    </button>
                  </div>
                  <div class="system-ui-container__sidebar__body">
                    <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_BUTTONS]} class="system-ui-container__sidebar__body_content">
                    </div>
                    
                    <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_GAME_VERSION_CONTAINER]} class="sidebar_gameVersion"></div>
                  </div>
                </div>
              </div>
            </div>`
  }
}
