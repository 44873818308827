import Game from './Game';

export default class GameInterface {
  constructor() {
    const game = new Game();

    this.show = (id, amount, currency) => {
      game.show(id, amount, currency);
    };

    this.ready = () => {
      return game.ready;
    }
  }
}
