import { blueWithDarkBlueStroke } from '../ui/fontStyles';
import BasePopup from './base';

export default class PopupInsufficientFunds extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);

    const title = this.getChildByName('error_title');
    const description = this.getChildByName('error_description');

    title.style = {
      ...title.style,
      ...blueWithDarkBlueStroke
    };

    description.style = {
      ...description.style,
      ...blueWithDarkBlueStroke,
    };

    description.text = window.OPWrapperService.localizations.getLocalizedText('error_description');

    this.getChildAt(0).alpha = 0.8;
  }

}
