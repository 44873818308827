import BaseController from '../baseSystemController';

export default class BaseTournamentController extends BaseController {
  constructor({ type, ...props }) {
    super({ type, ...props });
    this._data = null;
    this._tryToUpdateLocalizations = this._tryToUpdateLocalizations.bind(this);
    window.OPWrapperService.localizations.onTournamentLocalizationReceived.add(this._tryToUpdateLocalizations);
  }

  updateData(data) {
    const nextData = this._extractRelevantData(data);
    if (this._data === nextData) return;

    this._data = nextData;
    this._onDataUpdated();
  }

  beforeRemove() {
    super.beforeRemove();
    window.OPWrapperService.localizations.onTournamentLocalizationReceived.remove(this._tryToUpdateLocalizations);
    this._data = null;
  }

  _extractRelevantData(data) {
    const selector = this.dataSelector;
    if (!selector) return;

    return selector(data);
  }

  _onDataUpdated() {}

  _tryToUpdateLocalizations() {}

  get dataSelector() {}

  get elementsIdPrefix() {
    return `${super.elementsIdPrefix}__tournaments`;
  }

  get data() {
    return this._data;
  }
}
