import Event from '../utils/event';
import { getUrlParams } from '../utils/url';

export default new class GameModel {
  constructor() {
    this._bets = [1, 2, 5, 10, 20, 50, 100, 200];
    this._currentBetIndex = 0;
    this._betSelectorBlocked = false;

    this.onBetChanged = new Event();
    this.onBalanceChanged = new Event();
    this.onBetSelectorBlocked = new Event();

    this.currency = 'грн';

    this._balance = 20000;

    this.decimal = 2;
  }

  get currentBet() {
    return this._bets[this._currentBetIndex];
  }

  get currentBetIndex() {
    return this._currentBetIndex;
  }

  set balance(value) {
    this._balance = value;
    this.onBalanceChanged.call(this.balance);
  }

  get balance() {
    return this._balance;
  }

  set availableBets(value) {
    this._availableBets = value;
  }

  get availableBets() {
    return this._availableBets;
  }

  set bets(value) {
    this._bets = value
  }

  set currentBetIndex(value) {
    this._currentBetIndex = value;
    this.onBetChanged.call(this.currentBet);
  }

  get currentBetIndex() {
    return this._currentBetIndex;
  }

  get buttonPlusEnabled() {
    return (
      !this._betSelectorBlocked
      && this._currentBetIndex < this._bets.length - 1
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  }

  get buttonMinusEnabled() {
    return (
      !this._betSelectorBlocked
      && this._currentBetIndex > 0
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  }
  set betSelectorBlocked(value) {
    this._betSelectorBlocked = value;
    this.onBetSelectorBlocked.call();
  }

  get betSelectorBlocked() {
    return this._betSelectorBlocked;
  }

  increaseBet() {
    this._currentBetIndex = Math.min(this._currentBetIndex + 1, this._bets.length - 1);

    this.onBetChanged.call(this.currentBet);
  }

  decreaseBet() {
    this._currentBetIndex = Math.max(this._currentBetIndex - 1, 0);
    this.onBetChanged.call(this.currentBet);
  }

}
