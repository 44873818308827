export default {
  CANVAS_WIDTH_LANDSCAPE: 1190,
  CANVAS_HEIGHT_LANDSCAPE: 1060,

  CANVAS_WIDTH_PORTRAIT: 1190,
  CANVAS_HEIGHT_PORTRAIT: 1060,

  SAFE_WIDTH_LANDSCAPE: 1180,
  SAFE_HEIGHT_LANDSCAPE: 1040,

  SAFE_WIDTH_PORTRAIT: 1180,
  SAFE_HEIGHT_PORTRAIT: 1120
}
