import FlashLib from "flashlib";
import eButtonStateIndex from './eButtonStateIndex';

export default class BaseButton extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.previousFrameIndex = 1;

    this.init();
    this.addListeners();

    this.enabled = true;
  }

  init() {
    this.interactive = true;
    this.buttonMode = true;
  }

  addListeners() {
    this.on('mouseover', () => this.setFrameByIndex(eButtonStateIndex.ESI_HOVER), this);
    this.on('mouseout', () => this.setFrameByIndex(eButtonStateIndex.ESI_NORMAL), this);
    this.on('mousedown', () => this.setFrameByIndex(eButtonStateIndex.ESI_PRESSED), this);
    this.on('mouseup', () => this.setFrameByIndex(eButtonStateIndex.ESI_HOVER), this);

    this.on('touchstart', () => this.setFrameByIndex(eButtonStateIndex.ESI_PRESSED), this);
    this.on('touchend', () => this.setFrameByIndex(eButtonStateIndex.ESI_NORMAL), this);
    this.on('touchcancel', () => this.setFrameByIndex(eButtonStateIndex.ESI_NORMAL), this);
    this.on('touchendoutside', () => this.setFrameByIndex(eButtonStateIndex.ESI_NORMAL), this);
  }

  setFrameByIndex(frameIndex) {
    this.previousFrameIndex = this.currentFrameIndex;
    this.goToFrame(frameIndex);
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value) {
    if (this._enabled === value) return;
    this._enabled = value;
    if (value) {
      this.setFrameByIndex(this.previousFrameIndex);
      this.buttonMode = true;
    } else {
      this.setFrameByIndex(eButtonStateIndex.ESI_DISABLED);
      this.buttonMode = false;
    }
  }

  emit(type , ...arg) {
    if (!this._enabled) {
      if (['mouseout', 'touchendoutside', 'touchend', 'touchcancel'].includes(type)) {
        this.previousFrameIndex = eButtonStateIndex.ESI_NORMAL;
      } else if (type === 'mouseup') {
        this.previousFrameIndex = eButtonStateIndex.ESI_HOVER;
      }
      return;
    }

    super.emit(type, ...arg);
  }
}
