import eControllerTypes from './eControllerTypes';
import eStartScreenControllerTypes from '../system/tournamentsStartScreen/eControllerTypes';
import ControllerHeader from './tournamentsStartScreen/ControllerHeader';
import ControllerBonusDetails from './tournaments/ControllerBonusDetails';
import ControllerBanner from './tournaments/ControllerBanner';
import BaseTournamentController from './tournaments/baseTournamentController';
import ControllerModalBonusDetails from './ControllerModalTournamentDetails';
import {
  OP_WRAPPER_LOCALIZATION_PREFIX,
  OP_WRAPPER_TOURNAMENTS_LOCALIZATION_PREFIX,
  OP_WRAPPER_TOURNAMENTS_START_SCREEN
} from '../../../utils/constants';
import eTournamentStatus from './tournaments/eTournamentStatus';
import { formatOrientation } from '../../../utils/orientation';
import Queue from '../../../utils/queue';
import ScaleManager from '../../../scaleManager/ScaleManager';

export default class ControllerTournamentsStartScreen extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_TOURNAMENTS_START_SCREEN, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eButtonsTypes = {
      EBT_OK: 'button_ok'
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_OK]: () => this.hide(),
    };
    this._eEventTypes = {
      OK_CLICK: this.getEventName(this._eButtonsTypes.EBT_OK),
    };
    this._eElementsTypes = {
      EET_SCALE_ROOT: 'scale_root',
      EET_CONTAINER_HEADER: this.controllerTypes.ETSCT_HEADER,
      EET_CONTAINER_BANNER: this.controllerTypes.ETSCT_BANNER,
      EET_CONTAINER_BONUS_DETAILS: this.controllerTypes.ETSCT_BONUS_DETAILS,
      EET_CONTAINER_MODAL_BONUS_DETAILS: this.controllerTypes.ETSCT_MODAL_BONUS_DETAILS,
      EET_CONTAINER_INFO: 'container_info',
      EET_BUTTON_OK: this._eButtonsTypes.EBT_OK,
      EET_BUTTON_OK_LABEL: `${this._eButtonsTypes.EBT_OK}_label`,
      EET_TITLE: 'title',
      // EET_PRIZE_POOL: 'prize_pool',
      // EET_TOP_PRIZE: 'top_prize',
      // EET_MINIMAL_BET: 'minimal_bet',
      // EET_MULTIPLIER: 'multiplier',
      EET_WARNING: 'warning',
    };
    this._eControllersClasses = {
      [this.controllerTypes.ETSCT_HEADER]: ControllerHeader,
      [this.controllerTypes.ETSCT_BANNER]: ControllerBanner,
      [this.controllerTypes.ETSCT_BONUS_DETAILS]: ControllerBonusDetails,
      [this.controllerTypes.ETSCT_MODAL_BONUS_DETAILS]: ControllerModalBonusDetails,
    };
    this._initialLocalizations = {
      [this._eElementsTypes.EET_BUTTON_OK_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.button_ok`,
//      [this._eElementsTypes.EET_PRIZE_POOL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.prize_pool`,
//      [this._eElementsTypes.EET_TOP_PRIZE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.top_prize`,
//      [this._eElementsTypes.EET_MULTIPLIER]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.multiplier`,
      [this._eElementsTypes.EET_WARNING]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.warning`,
      [this._eElementsTypes.EET_CONTAINER_INFO]: `${OP_WRAPPER_TOURNAMENTS_LOCALIZATION_PREFIX}$id.start_screen.description`,
    };
    this._localizations = { ...this._initialLocalizations };
    // this._dynamicLocalizations = {
    //   [this._eElementsTypes.EET_MINIMAL_BET]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.no_minimal_bet`,
    //   [`${this._eElementsTypes.EET_MINIMAL_BET}_active`]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.startScreen.minimal_bet`,
    // };

    // const localisation = `<ul>
    //             <li><span>Tournament prize pool: EUR 3 000</span></li>
    //             <li><span>First Sprint - EUR 500</span></li>
    //             <li><span>Second Round - EUR 750</span></li>
    //             <li><span>Final Race - EUR 1250</span></li>
    //             <li><span>No minimum bet to participate</span></li>
    //             <li><span>Bonus Hour +20% points</span></li>
    //           </ul>
    //           <span>Nominations:</span>
    //           <ul>
    //             <li><span>For the highest number of bets: 125 EUR</span></li>
    //             <li><span>For highest total value of bets: 125 EUR</span></li>
    //             <li><span>For the biggest win: 125 EUR</span></li>
    //             <li><span>For the biggest loss:  125 EUR</span></li>
    //           </ul>`

    this._resolveExecution = null;
    this._currentLocalizationTournamentId = null;
    this._isOverlayToggler = true;
    this._showedTournamentsIds = new Set();

    this.init(container);
    this.hide();
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
    this._initControllers();
    this._addExtraControllersListeners();
    this._queue = new Queue(this._executeFromQueue.bind(this));
    this.canBeShown = false;
  }

  handleStart(tournamentId) {
    if (this._isAlreadyShowed(tournamentId)) return;
    this._queue.add(tournamentId);
  }

  show() {
    super.show();
    Object.values(this.controllers).forEach(controller => controller.onShow());
  }

  hide() {
    super.hide();
    Object.values(this.controllers).forEach(controller => controller.cleanup());
  }

  cleanup() {
    super.cleanup();
    this._resolveExecution && this._resolveExecution();
  }

  async _executeFromQueue(tournamentId) {
    return new Promise(resolve => {
      this._resolveExecution = resolve;
      const tournamentDetails = this._data.active.find(details => details.uniqueId === tournamentId);
      const tournamentData = this._data.data[tournamentId];

      if (tournamentDetails) { //in case when user didn't resolve previous start screen promise and this tournament has been already finished
        Object.values(this.controllers).forEach(controller => controller.updateData({
          details: tournamentDetails,
          data: tournamentData,
          status: eTournamentStatus.ETS_ACTIVE,
        }));

        this._update(tournamentDetails);
        this.show();
      } else {
        resolve();
      }

      this._markAsShowed(tournamentId);
    });
  }

  _onDataUpdated() {
    super._onDataUpdated();
    if (this._data.active.length) {
      const id = this._data.active[this._data.active.length - 1].uniqueId;
      this.handleStart(id);
    }
  }

  _initControllers() {
    this._controllers = {};
    for (let key in this.controllerTypes) {
      const type = this.controllerTypes[key];
      const container = this.interactiveElements[type];
      if (!container) throw new Error(`Can't find container for controller type: '${type}'`);

      const controller = this._eControllersClasses[type];
      this._controllers[type] = new controller(container, { type });
      this._controllers[type]._type = type;
      this._addControllerListeners(type);
    }
  }

  _addControllerListeners(type) {
    const controller = this._controllers[type];
    if (!controller.events) return;

    for (let event of Object.values(controller.events[type])) {
      const events = this._getEventsRecursively(event);

      events.forEach(e => {
        this._controllers[type].on(e, (params) => {
          console.log(e);
          this.emit(e, params)
        });
      });
    }
  }

  _addExtraControllersListeners() {
    const controllerHeader = this.controllers[this.controllerTypes.ETSCT_HEADER];
    const controllerDetails = this.controllers[this.controllerTypes.ETSCT_BONUS_DETAILS];
    const controllerModalDetails = this.controllers[this.controllerTypes.ETSCT_MODAL_BONUS_DETAILS];
    const events = this.events[this.controllerType];

    controllerHeader.on(events[this.controllerTypes.ETSCT_HEADER].CLOSE_CLICK, () => this.hide());
    controllerDetails.on(events[this.controllerTypes.ETSCT_BONUS_DETAILS].DETAILS_CLICK, () => controllerModalDetails.show());
  }

  _getEventsRecursively(event, events = []) {
    if (typeof event === 'object') {
      const keys = Object.keys(event);
      for (let key of keys) {
        this._getEventsRecursively(event[key], events);
      }
    } else if (typeof event === 'string') {
      events.push(event);
    }

    return events;
  }

  _update(details) {

//    this._localizationValues = {
//      [this._eElementsTypes.EET_PRIZE_POOL]: { $pool: 100 },
//      [this._eElementsTypes.EET_TOP_PRIZE]: { $prize: 10 },
//      [this._eElementsTypes.EET_MINIMAL_BET]: { $bet: 2 },
//      [this._eElementsTypes.EET_MULTIPLIER]: { $multiplier: 20 },
//    }
//
//    let minimalBetKey = this._eElementsTypes.EET_MINIMAL_BET;
//    if (details.minBet) minimalBetKey += '_active';
//
//    this._localizations = {
//      ...this._initialLocalizations,
//      [this._eElementsTypes.EET_MINIMAL_BET]: this._dynamicLocalizations[minimalBetKey],
//    }

    this._currentLocalizationTournamentId = details.rulesLocalizationKey || details.uniqueId;
    this.set(this._eElementsTypes.EET_TITLE, details.title);
    this._localizationKeysValues = {
      [this._eElementsTypes.EET_CONTAINER_INFO]: { $id: this._currentLocalizationTournamentId },
    }
    this._updateLocalizations();
  }

  _isAlreadyShowed(tournamentId) {
    let showed = this._showedTournamentsIds.has(tournamentId);

    if (!showed) {
      const key = `${OP_WRAPPER_TOURNAMENTS_START_SCREEN}_${tournamentId}`;

      try {
        let localStorageData = localStorage.getItem(key);
        if (localStorageData && JSON.parse(localStorageData).checked) showed = true;
      } catch (e) {
        console.error(e);
      }
    }

    return showed;
  }

  _markAsShowed(tournamentId) {
    this._showedTournamentsIds.add(tournamentId);

    const key = `${OP_WRAPPER_TOURNAMENTS_START_SCREEN}_${tournamentId}`;

    try {
      localStorage.setItem(key, JSON.stringify({ checked: true, timestamp: Date.now() }));
    } catch (e) {
      console.error(e);
    }
  }

  _tryToUpdateLocalizations({ localizationId }) {
    super._tryToUpdateLocalizations();
    if (this._currentLocalizationTournamentId === localizationId) this._updateLocalizations();
  }

  set canBeShown(value) {
    this._canBeShown = value;
    if (this._canBeShown) {
      this._queue.continue();
    } else {
      this._queue.pause();
    }
  }

  get canBeShown() {
    return this._canBeShown;
  }

  get controllers() {
    return this._controllers;
  }

  get events() {
    for (let controller of Object.values(this.controllers)) {
      Object.assign(this._eEventTypes, controller.events);
    }

    return { [this.controllerType]: this._eEventTypes }
  }

  get controllerTypes() {
    return eStartScreenControllerTypes;
  }

  get dataSelector() {
    return (data) => data;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.orientation;
    const scaleBeforeScaleManagerInitialized = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    const uiScale = this._scaleData.scale || scaleBeforeScaleManagerInitialized;
    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const currentSizes = ScaleManager.currentSizes ? ScaleManager.currentSizes : this._defaultSizes[orientation];
    this.root.setAttribute('data-orientation', formatOrientation(orientation));
    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.root,
      relativeScale: uiScale,
      defaultWidth: Math.min(currentSizes.width, this._scaleData.innerWidth / uiScale),
      defaultHeight: Math.min(currentSizes.height, this._scaleData.innerHeight / uiScale)
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__tournaments system-ui-container__tournaments_start_screen">
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_MODAL_BONUS_DETAILS]}></div>
      
      <div class="system-ui-container__tournaments_main">
        <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_HEADER]}>
        </div>

        <div class="system-ui-container__tournaments_main__title">
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>
          </span>
        </div>

        <div class="system-ui-container__tournaments_main__body">

          <div class="system-ui-container__tournaments_main__body_description">
                       
            <div class="system-ui-container__tournaments_main__body_description_promo_block">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_BANNER]}></div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_BONUS_DETAILS]}></div>
            </div>
          </div>

          <div class="system-ui-container__tournaments_main__body_content">
            <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_INFO]} class="system-ui-container__tournaments_start_screen__info">
              
            </div>
            <div class="system-ui-container__tournaments_start_screen__submit_container">
              <div class="system-ui-container__tournaments_start_screen__submit_container__warning">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="019-information" clip-path="url(#clip0_492_1001)">
                  <g id="ESSENTIAL UI">
                  <path id="Vector" d="M46.9734 9.66845C46.0706 5.5922 42.1191 1.85814 38.0316 1.06501C35.5416 0.567198 28.5938 -0.0478016 24 1.08499e-05C19.4062 -0.0506142 12.4584 0.562511 9.96844 1.0622C5.88094 1.85814 1.92937 5.5922 1.03125 9.66845C0.511875 12.1434 0.0075 15.5109 0 20.1075C0.0075 24.7013 0.511875 28.0697 1.03125 30.5456C1.93406 34.6219 5.88562 38.3559 9.97312 39.1491C11.3175 39.4172 13.9613 39.7209 16.8328 39.9347C17.1578 40.4453 17.5106 40.9891 17.8913 41.5659C19.4156 43.8741 20.5809 45.4819 21.4641 46.6022C22.9341 48.466 25.0744 48.466 26.5444 46.6022C27.4275 45.4819 28.5928 43.8741 30.1172 41.5659C30.4978 40.9884 30.8506 40.4447 31.1756 39.9347C34.0472 39.7209 36.6919 39.4172 38.0363 39.1491C42.1237 38.3559 46.0753 34.6219 46.9781 30.5456C47.4919 28.0697 47.9972 24.7031 48.0047 20.1075C47.9925 15.51 47.4872 12.1444 46.9734 9.66845Z" fill="#F68407"/>
                  <path id="Vector_2" d="M24 1.08499e-05C19.4063 -0.0506142 12.4584 0.562511 9.96844 1.0622C5.88094 1.85814 1.92938 5.5922 1.03125 9.66845C0.511875 12.1434 0.0075 15.5109 0 20.1066C0.0075 24.7003 0.511875 28.0697 1.03125 30.5456C1.56281 32.9484 3.15563 35.2331 5.21063 36.8653C7.2002 37.3393 9.23851 37.5781 11.2838 37.5769C25.6866 37.5769 37.3622 25.9013 37.3622 11.4994C37.3654 7.74783 36.5563 4.04005 34.9903 0.630948C31.7878 0.271886 27.2906 -0.0337392 24 1.08499e-05Z" fill="#FE9D11"/>
                  <g id="Group">
                  <path id="Vector_3" d="M26.6512 33.734C26.5888 34.0259 26.4432 34.2935 26.2322 34.5046C26.0211 34.7157 25.7535 34.8612 25.4616 34.9237C24.493 35.0737 23.507 35.0737 22.5384 34.9237C22.2464 34.8615 21.9787 34.716 21.7676 34.5049C21.5565 34.2938 21.411 34.026 21.3487 33.734C21.2 32.7656 21.2 31.7802 21.3487 30.8118C21.4114 30.5203 21.557 30.2531 21.7681 30.0425C21.9792 29.8319 22.2467 29.6869 22.5384 29.625C23.507 29.475 24.493 29.475 25.4616 29.625C25.7536 29.6871 26.0214 29.8325 26.2325 30.0437C26.4437 30.2548 26.5891 30.5226 26.6512 30.8146C26.8 31.783 26.8 32.7684 26.6512 33.7368V33.734Z" fill="white"/>
                  <path id="Vector_4" d="M21.3412 8.91937C21.3412 8.21423 21.6213 7.53796 22.1199 7.03935C22.6185 6.54074 23.2948 6.26062 23.9999 6.26062C24.7051 6.26062 25.3813 6.54074 25.88 7.03935C26.3786 7.53796 26.6587 8.21423 26.6587 8.91937V23.8322C26.6587 24.5373 26.3786 25.2136 25.88 25.7122C25.3813 26.2108 24.7051 26.4909 23.9999 26.4909C23.2948 26.4909 22.6185 26.2108 22.1199 25.7122C21.6213 25.2136 21.3412 24.5373 21.3412 23.8322V8.91937Z" fill="white"/>
                  </g>
                  </g>
                  </g>
                  <defs>
                  <clipPath id="clip0_492_1001">
                  <rect width="48" height="48" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_WARNING]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_WARNING])}</span>
              </div>
              
              <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_OK]} class="secondary">
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_OK_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_BUTTON_OK_LABEL])}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      
     
    </div>`
  }
}


//<ul>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PRIZE_POOL]}>Tournament prize pool: EUR 3 000</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TOP_PRIZE]}>First Sprint - EUR 500</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MINIMAL_BET]}>Second Round - EUR 750</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MULTIPLIER]}>Final Race - EUR 1250</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MULTIPLIER]}>No minimum bet to participate</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MULTIPLIER]}>Bonus Hour +20% points</span></li>
//               </ul>
//               <span>Nominations:</span>
//               <ul>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PRIZE_POOL]}>For the highest number of bets: 125 EUR</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TOP_PRIZE]}>For highest total value of bets: 125 EUR</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MINIMAL_BET]}>For the biggest win: 125 EUR</span></li>
//                 <li>${this._getBullet()}<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MULTIPLIER]}>For the biggest loss:  125 EUR</span></li>
//               </ul>
