import FlashLib from 'flashlib';
import { blueWithDarkBlueStroke } from './fontStyles';
import GameModel from '../models/GameModel';

export default class BetView extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.buttonMinus = this.getChildByName('buttonMinus');
    this.buttonMinus.getChildByName('label').text = '-';
    this.buttonMinus.on('pointertap', this.onMinusCLicked.bind(this));

    this.buttonPlus = this.getChildByName('buttonPlus');
    this.buttonPlus.on('pointertap', this.onPlusClicked.bind(this));

    this.label = this.getChildByName('labelValue');
    this.labelDefaultFontSize = this.label.style.fontSize;

    this.title = this.getChildByName('game_bet');
    this.title.style = { ...this.title.style, ...blueWithDarkBlueStroke };

    GameModel.onBetChanged.add(this.onBetChanged.bind(this));
    GameModel.onBetSelectorBlocked.add(this.updateButtonsEnabled.bind(this));

    this.onBetChanged(GameModel.currentBet);
  }

  onMinusCLicked() {
    GameModel.decreaseBet();
  }

  onPlusClicked() {
    GameModel.increaseBet();
  }

  onBetChanged(value) {
    const text = `${value} ${GameModel.currency}`;
    const fontSize = text.length < 12 ? this.labelDefaultFontSize : 32;
    this.label.style.fontSize = fontSize;
    this.label.text = text;

    this.updateButtonsEnabled();
  }

  updateButtonsEnabled() {
    this.buttonPlus.enabled = GameModel.buttonPlusEnabled;
    this.buttonMinus.enabled = GameModel.buttonMinusEnabled;
  }
}
