import FlashLib from 'flashlib';

export default class BasePopup extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
    this.visible = false;

    this.getChildByName('button_x').on('pointertap', () => this.hide());
    this.getChildByName('button_close').on('pointertap', () => this.hide());
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

}
