import FlashLib from 'flashlib';

import BaseButton from './base/Button';

import TopPanel from './ui/TopPanel';
import ButtonBet from './ui/ButtonBet';
import CoinView from './ui/CoinView';
import BetView from './ui/BetView';

import PopupInfo from './popups/Info';
import PopupInsufficientFunds from './popups/insufficientFunds';

FlashLib.registerClass('Button', BaseButton);

FlashLib.registerClass('TopPanel', TopPanel);
FlashLib.registerClass('ButtonBet', ButtonBet);
FlashLib.registerClass('CoinView', CoinView);
FlashLib.registerClass('BetView', BetView);
FlashLib.registerClass('PopupInfo', PopupInfo);
FlashLib.registerClass('PopupInsufficientFunds', PopupInsufficientFunds);
